import React from "react";
import { IonPage } from "@ionic/react";

import "./Home.css";
import { QuillForms } from "../components/quillform";

import { db } from "../submit";

export default function ContactUs() {
  return (
    <IonPage>
      <div style={{ width: "100%", height: "100vh" }}>
        <QuillForms
          blocks={[
            {
              name: "short-text",
              id: "contact_1",
              attributes: {
                classnames: "first-block",
                required: true,
                label: "Let's start with your name",
              },
            },
            {
              name: "email",
              id: "contact_2",
              attributes: {
                required: true,
                label: "What email address can we reach you at?",
              },
            },
          ]}
          onSubmit={(e: any) => {
            const data = {
              name: e.answers.contact_1.value,
              email: e.answers.contact_2.value,
            };
            db.contactUs(data).then((res) => {
              console.log("res", res);
              window.location.href = "/";
            });
            console.log("data", e);
          }}
          onClose={() => {
            window.location.href = "/";
          }}
        />
      </div>
    </IonPage>
  );
}
