import React from "react";
import { IonButton, IonContent, IonPage } from "@ionic/react";
import "./Home.css";
import { useHistory } from "react-router";
import { animated, easings, useSpring } from "@react-spring/web";
import { reverse } from "lodash";

const Home: React.FC = () => {
  const history = useHistory();

  const top_springs = useSpring({
    from: { rotate: 0, x: 0, y: 0 },
    to: [
      {
        rotate: 0,
        x: 10,
        y: 10,
        config: {
          duration: 2800,
        },
      },
      {
        rotate: 0,
        x: 0,
        y: 0,
        config: {
          duration: 3000,
        },
      },
    ],
    loop: true,
  });

  const bottom_springs = useSpring({
    from: { rotate: 0, x: 0, y: 0 },
    to: [
      {
        rotate: 0,
        x: 20,
        y: 0,
        config: {
          duration: 2700,
        },
      },
      {
        rotate: 0,
        x: 0,
        y: 0,
        config: {
          duration: 3200,
        },
      },
    ],
    loop: true,
    reverse: true,
  });
  const logo_springs = useSpring({
    from: { x: -60, y: -60, scale: 1 },
    to: [
      {
        x: 0,
        y: 0,
        scale: 1,
        config: {
          duration: 300,
        },
      },
    ],
  });

  const explainer_springs = useSpring({
    from: { x: 60, y: 60, scale: 1 },
    to: [
      {
        x: 0,
        y: 0,
        scale: 1,
        config: {
          duration: 300,
        },
      },
    ],
  });

  return (
    <IonPage>
      <IonContent fullscreen style={{ backgroundColor: "#191818" }}>
        <div
          style={{
            height: "calc(100vh )",
            display: "flex",
            backgroundImage: "url(/assets/background1.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: "#191818",
            paddingLeft: 60,
            paddingRight: 60,
            paddingTop: 30,
          }}
        >
          <div
            style={{
              flex: 1,
              textAlign: "center",
            }}
          >
            <div style={{ color: "white", textAlign: "center" }}>
              <animated.div
                style={{
                  fontWeight: 900,
                  fontSize: 34,
                  paddingBottom: 30,
                  width: "100%",
                  textAlign: "center",
                  ...logo_springs,
                }}
              >
                <img
                  src="/assets/valid_white_sticker.PNG"
                  style={{ width: 400, marginTop: 50 }}
                />
              </animated.div>

              <animated.div
                style={{
                  paddingTop: 20,
                  fontSize: 18,
                  maxWidth: 700,
                  margin: "auto",
                  textAlign: "center",
                  ...explainer_springs,
                }}
              >
                <span style={{ color: "#7ED957", fontWeight: 600 }}></span>{" "}
                Thank you for your interest in Valid. We are early in the
                processes of developing a product that will help patients and
                providers navigate the complex world of healthcare insurance
                verification. <br />
                <br /> We are currently in the process of conducting research to
                better understand the needs of patients and providers. If you
                are interested in participating in our research, please click on
                the appropriate link below.
                <div
                  style={{
                    paddingTop: 100,
                    maxWidth: 400,
                    margin: "auto",
                  }}
                >
                  <IonButton
                    onClick={() => {
                      history.push("/provider-survey");
                    }}
                    target="_blank"
                    shape="round"
                    expand="block"
                    color="warning"
                  >
                    Join our research
                  </IonButton>
                </div>
                {/* <div
                style={{
                  paddingTop: 30,
                  maxWidth: 400,
                }}
              >
                <IonButton
                  href="https://www.canva.com/design/DAFooATg4CE/oNS7TSC5VaWKvXxxqKfJkw/view"
                  target="_blank"
                  expand="block"
                  shape="round"
                  color="warning"
                >
                  Slide Deck
                </IonButton>
              </div> */}
                <div
                  style={{
                    paddingTop: 30,
                    maxWidth: 400,
                    margin: "auto",
                  }}
                >
                  <IonButton
                    onClick={() => {
                      history.push("/contact-us");
                    }}
                    target="_blank"
                    expand="block"
                    shape="round"
                    color="light"
                  >
                    Join the mailing list
                  </IonButton>
                </div>
              </animated.div>
              <div style={{ height: 100, width: "100%" }}></div>
            </div>
          </div>
        </div>
        <animated.div
          style={{
            position: "fixed",
            width: 300,
            height: 210,
            top: -30,
            left: -30,
            zIndex: 1,
            ...top_springs,
          }}
        >
          <img
            style={{ width: "100%", height: "auto" }}
            src="/assets/top_element2.png"
          />
        </animated.div>
        <animated.div
          style={{
            backgroundImage: "url(/assets/bottom_element.png)",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            position: "fixed",
            width: 300,
            height: 210,
            bottom: -120,
            left: 0,
            zIndex: 1,
            ...bottom_springs,
          }}
        ></animated.div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
