import React from "react";
import { Form } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import { IonButton } from "@ionic/react";
registerCoreBlocks();

export function QuillForms(props) {
  let FormTheme = {
    backgroundColor: "#191818",
    questionsColor: "#eee",
    answersColor: "#eee",
    buttonsFontColor: "#191818",
    buttonsBgColor: "rgb(249, 196, 12)",
    errorsFontColor: "#000",
  };

  return (
    <>
      <Form
        formId="1"
        formObj={{
          theme: FormTheme,
          blocks: props.blocks,
        }}
        onSubmit={(data, { setIsSubmitting }) => {
          props.onSubmit(data);
          setTimeout(() => {
            setIsSubmitting(false);
          }, 5000);
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          zIndex: 145564564564500,
        }}
      >
        <IonButton
          shape="round"
          size="small"
          onClick={() => {
            props.onClose();
          }}
        >
          CLOSE
        </IonButton>
      </div>
      <div
        style={{
          position: "absolute",
          top: 10,
          left: 20,
          zIndex: 1454564564564500,
          width: "140px",
        }}
        onClick={() => {
          location.href = "/";
        }}
      >
        <img src="/assets/valid_white.PNG" alt="logo" />
      </div>
    </>
  );
}
