import axios from "axios";
import PocketBase from "pocketbase";

const pb = new PocketBase("https://us1.valid.care");

export const db = {
  async contactUs(data: any) {
    return await pb.collection("contacts").create(data);
  },
  async providerSurvey(data: any) {
    return await pb.collection("provider_surveys").create(data);
  },
};
