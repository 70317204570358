import React from "react";
import { IonContent, IonPage } from "@ionic/react";

import "./Home.css";
import { QuillForms } from "../components/quillform";

import { db } from "../submit";
import _ from "lodash";

export default function ProviderSurvey() {
  return (
    <IonPage>
      <IonContent fullscreen>
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 100px)",
            marginBottom: 20,
            marginTop: 80,
          }}
        >
          <QuillForms
            blocks={[
              {
                name: "short-text",
                id: "name",
                attributes: {
                  classnames: "first-block",
                  required: true,
                  label: "Hello, what's your name?",
                  description:
                    "We're excited to learn more about you and your practice. This survey should take less than 5 minutes to complete. If selected to participate in our research, you will be compensated for your time.",
                },
              },
              {
                name: "multiple-choice",
                id: "hear_about",
                attributes: {
                  required: true,
                  multiple: true,
                  verticalAlign: true,
                  label:
                    "Nice to meet you, {{field:name}}. How did you hear about Valid?",
                  choices: [
                    {
                      label: "Contacted directly",
                      value: "Contacted directly",
                    },
                    {
                      label: "Through a colleague/friend",
                      value: "Through a colleague/friend",
                    },
                    {
                      label: "Another agency recommended",
                      value: "Another agency recommended",
                    },
                    {
                      label: "Information presented at a meeting",
                      value: "Information presented at a meeting",
                    },
                    {
                      label: "Online search",
                      value: "Online search",
                    },
                    {
                      label: "Social media/marketing",
                      value: "Social media/marketing",
                    },
                    {
                      label: "Other",
                      value: "Other",
                    },
                  ],
                },
              },
              {
                name: "multiple-choice",
                id: "provider",
                attributes: {
                  required: true,
                  verticalAlign: true,
                  multiple: false,
                  label:
                    "Are you a service/care provider within the healthcare industry?",
                  choices: [
                    {
                      label: "Yes",
                      value: "yes",
                    },
                    {
                      label: "No",
                      value: "no",
                    },
                  ],
                },
              },
              {
                name: "multiple-choice",
                id: "role",
                attributes: {
                  required: true,
                  verticalAlign: true,
                  multiple: true,
                  label: "Great! How would you define your role?",
                  choices: [
                    {
                      label: "Administrative/Executive Team",
                      value: "Administrative/Executive Team",
                    },
                    {
                      label: "Finance/Billing Team",
                      value: "Finance/Billing Team",
                    },
                    {
                      label: "Department Manager/Supervisor",
                      value: "Department Manager/Supervisor",
                    },
                    {
                      label: "Information Technology",
                      value: "Information Technology",
                    },
                    {
                      label: "Other",
                      value: "other",
                    },
                  ],
                },
              },
              {
                name: "group",
                id: "resources",
                attributes: {
                  required: true,
                  label:
                    "Our primary aim is to cure the administrative overhead that plagues the healthcare industry related to insurance.",
                  description:
                    "Help us understand how this problem effects your practice or agency by sharing what resources you currently have dedicated to it. <br><br><br><br>FTE = Full Time Equivalent or Employee  <br><br><br><br> Note: Answer 4 FTE for 4 or more",
                },
                innerBlocks: [
                  {
                    name: "slider",
                    id: "resources_verification",
                    attributes: {
                      label: "Verification/Eligibility",
                      min: 0,
                      max: 4,
                      step: 0.25,
                      suffix: " FTE ",
                    },
                  },
                  {
                    name: "slider",
                    id: "resources_claims",
                    attributes: {
                      label: "Claim Submission/Management",
                      min: 0,
                      max: 4,
                      step: 0.25,
                      suffix: " FTE ",
                    },
                  },
                  {
                    name: "slider",
                    id: "resources_reverification",
                    attributes: {
                      label: "Re-verifications",
                      min: 0,
                      max: 4,
                      step: 0.25,
                      suffix: " FTE ",
                    },
                  },
                  {
                    name: "slider",
                    id: "resources_collections",
                    attributes: {
                      label: "Collection",
                      min: 0,
                      max: 4,
                      step: 0.25,
                      suffix: " FTE ",
                    },
                  },
                ],
              },
              {
                name: "short-text",
                id: "missing",
                attributes: {
                  required: false,
                  label:
                    "What is the most difficult/problematic aspects of dealing with insurance within your agency?",
                },
              },
              {
                name: "multiple-choice",
                id: "hours",
                attributes: {
                  required: true,
                  verticalAlign: true,
                  multiple: false,
                  label: "If selected, would you spare 3-5 hours…",
                  description:
                    "This would allow us to fully understand the challenges faced by your team.",
                  choices: [
                    {
                      label: "Yes, we’d offer more than 3-5 hours if needed",
                      value: "Yes, we’d offer more than 3-5 hours if needed",
                    },
                    {
                      label: "Yes, I am willing to share 3-5 hours",
                      value: "Yes, I am willing to share 3-5 hours",
                    },
                    {
                      label:
                        "Maybe, but I’d need more information about the problem",
                      value:
                        "Maybe, but I’d need more information about the problem",
                    },
                    {
                      label: "No, this isn’t really an issue for me",
                      value: "No, this isn’t really an issue for me",
                    },
                  ],
                },
              },
              {
                name: "group",
                id: "contact",
                attributes: {
                  required: true,
                  label: "You contact info",
                  description:
                    "So we contact you with the results of our research.",
                },
                innerBlocks: [
                  {
                    name: "short-text",
                    id: "phone",
                    attributes: {
                      required: false,
                      label: "Phone",
                    },
                  },
                  {
                    name: "email",
                    id: "email",
                    attributes: {
                      required: false,
                      label: "Email",
                    },
                  },
                  {
                    name: "short-text",
                    id: "company",
                    attributes: {
                      required: false,
                      label: "Company",
                    },
                  },
                ],
              },
            ]}
            onSubmit={(e: any) => {
              const data = {
                company: e.answers.company.value,
                email: e.answers.email.value,
                hear_about: e.answers.hear_about.value.join(", "),
                hours: e.answers.hours.value.join(", "),
                missing: e.answers.missing.value,
                name: e.answers.name.value,
                phone: e.answers.phone.value,
                provider: e.answers.provider.value.join(", "),
                resources_claims: e.answers.resources_claims.value,
                resources_collections: e.answers.resources_collections.value,
                resources_reverification:
                  e.answers.resources_reverification.value,
                resources_verification: e.answers.resources_verification.value,
                role: e.answers.role.value.join(", "),
              };
              db.providerSurvey(data).then((res) => {
                console.log("res", res);
                window.location.href = "/";
              });
              console.log("data", e);
            }}
            onClose={() => {
              window.location.href = "/";
            }}
          />
        </div>
      </IonContent>
    </IonPage>
  );
}
